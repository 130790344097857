export default {
    data() {
        return {
            period: 'daily',
            periods: ['daily', 'weekly', 'monthly'],
        }
    },
    methods: {
        updatePeriod(newPeriod) {
            if (this.periods.includes(newPeriod)) {
                return this.period = newPeriod
            }

            this.period = 'daily'
        }
    }
}