export default {
    all_teams: 'All teams',
    avatar: {
        description: 'Adopt the KeyLime vibe by submitting a front-facing, well-lighted picture of your face. We will analyze it and send you directly to the Keys!',
        edit: 'Create an Avatar',
        regen: 'Generate Another Version',
        your: 'Your KeyLime Avatar'
    },
    average_sentiment_for: 'Average Sentiment from :start to :end',
    back: 'Back',
    back_to_employees: 'Back to Employees',
    birthdate: 'Date of Birth',
    blockers: 'Blockers',
    brief: {
        categories: {
            challenges: 'Challenges',
            projects: 'Project Updates',
            sentiments: 'Sentiment'
        },
        custom: 'Custom Brief',
        custom_team: 'For All Employees of',
        date: 'From :start to :end',
        export: 'Export Brief',
        generate: 'Generate Custom Brief',
        listen: 'Listen to the Brief',
        stop: 'Stop the listening',
        view: 'View the brief'
    },
    briefs: 'Briefs',
    bugs: 'Bugs',
    cancel: 'Cancel',
    challenges: {
        extreme: 'Extreme Challenge',
        high: 'High Challenge',
        low: 'Low Challenge',
        medium: 'Medium Challenge',
        moderate: 'Moderate Challenge'
    },
    challenges_heatmap: 'Challenges Heatmap',
    chat_with_keylime: 'Chat With KeyLime',
    client: 'Client',
    close_window: 'Close Window',
    coming_soon: 'Coming Soon...',
    compared_yesterday: ':comparison compared to yesterday',
    complete_stand_up: 'Complete my Stand-Up',
    completion_and_submission: 'Employee took :completion_time to answer KeyPoint and submitted :submission_time.',
    dark_mode: {
        switch: {
            dark: 'Switch to dark mode',
            light: 'Switch to light mode'
        }
    },
    dates: {
        close: 'Close Dates',
        select: 'Select Dates'
    },
    desktop_only: 'Desktop only',
    done: 'Done',
    email: 'Email',
    employee: {
        add: 'Add an employee',
        see_vitals: {
            plural: 'See Employees KeyVitals',
            singular: 'See Employee\'s KeyVitals'
        },
        switch: 'Switch to employee view',
        team_notice: 'An employee\'s team affiliation can only be updated through their profile.',
        update: 'Edit Employee Profile'
    },
    employees: 'Employees',
    emotional_state: 'Emotional State',
    energy: 'Energy',
    engagement_quality: 'Engagement Quality',
    errors: {
        date: 'The date must respect the YYYY/MM/DD format',
        max_2mb: 'File too large. (Max 2mb)',
        special_characters: 'The field\'s value must not contain special characters.',
        server_update: 'Your changes could not be saved. Please retry later.'
    },
    find_something: 'Find something',
    first_name: 'First Name',
    focus: 'Focus',
    full_name: 'Full Name',
    generate_brief: 'Generate Brief',
    hub: 'Hub',
    interactions: 'Interactions',
    job: 'Job',
    last_name: 'Last Name',
    latest_briefs: 'Latest Briefs',
    listen: 'Listen',
    login: 'Login',
    logout: 'Logout',
    main_menu: 'Main Menu',
    manager: {
        switch: 'Switch to manager view'
    },
    monthly_score: 'Monthly average',
    name: 'Name',
    no_briefs: 'No brief matches the search criteria.',
    ongoing_tasks: 'Ongoing Tasks',
    or: 'or',
    password: 'Password',
    period: {
        daily: {
            comparison: ':comparison from yesterday'
        },
        monthly: {
            comparison: ':comparison from last month'
        },
        weekly: {
            comparison: ':comparison from last week'
        },
    },
    photo: {
        choose: 'Choose Photo',
        drag_drop: 'Drag & drop or select a photo from your computer',
        retake: 'Retake Photo',
        take: 'Take Photo',
        use: 'Use this Photo'
    },
    powered_by_ai: 'Powered by AI',
    productivity: 'Productivity',
    reset: 'Reset',
    see_all: 'See All',
    sentiment: 'Sentiment',
    sentiment_for: 'Sentiment for',
    settings: 'Settings',
    short_description: 'Short Description',
    snap: 'Snap',
    sort: 'Sort',
    squeeze: {
        explore_all: 'Explorez tous les Squeezes',
        export: 'Export Squeeze',
        title: 'Refresh on :period\'s Squeeze - :date'
    },
    squeezes: 'Squeezes',
    stand_up_completed: 'Your daily stand-up has been completed. Comeback tomorrow to answer another one!',
    stress_level: 'Stress Level',
    submit: {
        changes: 'Submit Changes',
        photo: 'Submit Photo'
    },
    summary: {
        long: 'Long Version',
        short: 'Short Summary'
    },
    team: {
        add: 'Add Team',
        label: 'Team',
        notice: 'Please note that the data displayed for the teams\' KeyVitals is derived from the average of each employee\'s individual data, rather than specific team data.',
        see_vitals: {
            plural: 'See Teams KeyVitals',
            singular: 'See Team\'s KeyVitals'
        },
        update: 'Edit Team Profile'
    },
    teams: 'Teams',
    tech: 'Tech',
    today: 'today',
    todays_keylime: 'Today\'s KeyLime',
    todays_score: 'Today\'s score',
    todays_sentiment: 'Today\'s sentiment',
    todo: 'To do',
    view_switch: {
        employee: 'Switch to Employee View',
        manager: 'Switch to Manager View'
    },
    weekly_score: 'Weekly average',
    welcome: 'Welcome',
    work_email_address: 'Work Email Address',
    work_title: 'Work Title',
    x_days_ago: ':X days ago',
    x_days_ago_sentiment: 'X days ago sentiment',
    yesterday: 'Yesterday',
    yesterdays_sentiment: 'Yesterday\'s sentiment',
    you: 'You'
}