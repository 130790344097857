<template>
  <svg ref="svgRef" class="inline-block w-[1em] h-[1em] fill-current leading-none">
    <use :xlink:href="`#${id}`"/>
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    id: {
      type: String,
      required: true
    }
  }
}
</script>
