<template>
  <Transition name="fade">
    <aside v-if="$modal.type" @click.stop="$modal.close"
           class="fixed inset-0 w-full h-full z-50 bg-modal flex justify-center items-center cursor-pointer">
      <div class="bg-white-900 p-10 rounded-2xl relative min-w-232 max-w-full dark:bg-gray-800 cursor-default" @click.stop>
        <button @click.stop="$modal.close"
                class="absolute top-5 right-5 w-10 aspect-square rounded-lg border border-light_gray-200 flex items-center justify-center hover:bg-light_gray-200 transition-colors duration-250 group">
          <span v-for="i in 2" :key="`modal-close-span-${i}`"
                :class="['w-3 h-0.5 bg-black block absolute dark:bg-white-900 transition-colors dark:group-hover:bg-gray-800', {'rotate-45': i === 1, '-rotate-45': i === 2}]"/>
        </button>

        <component :is="modal"/>
      </div>
    </aside>
  </Transition>
</template>

<script>
import {AvatarEditModal, BriefModal, EmployeeUpdateModal, SettingsModal, TeamUpdateModal} from '@/components/modal/blocks'
import {LangRouterLink} from '@/components/helpers'

export default {
  name: 'Modal',
  components: {LangRouterLink},
  computed: {
    modal() {
      switch (this.$modal.type) {
        case this.$consts.MODAL_TYPES.AVATAR:
          return AvatarEditModal
        case this.$consts.MODAL_TYPES.BRIEF:
          return BriefModal
        case this.$consts.MODAL_TYPES.EMPLOYEE_UPDATE:
          return EmployeeUpdateModal
        case this.$consts.MODAL_TYPES.SETTINGS:
          return SettingsModal
        case this.$consts.MODAL_TYPES.TEAM_UPDATE:
          return TeamUpdateModal
        default:
          return null
      }
    }
  },
  methods: {
    logout() {
      this.$auth.logout()
      this.modal.close()
    }
  }
}
</script>