export default {
    computed: {
        valid() {
            return !Object.values(this.form.fields).some(({valid}) => valid === false)
        }
    },
    methods: {
        async submit() {
            if (this.valid) {
                this.form.server.error = false
                this.form.server.submitted = true
                try {
                    await this.handle()
                    this.$modal.close()
                } catch (exception) {
                    //todo handle exception
                    this.form.server.error = true
                    this.form.server.submitted = false
                }
            }
        }
    }
}