import {defineStore} from 'pinia'
import CONSTANTS from '@/config/CONSTANTS.js'
import {baseApi} from '@/extensions/http'
import i18n from '@/i18n'
import {localizedDate} from '@/helpers/localizedDate.js'
import {useAuthStore} from '@/stores/auth.js'

export const useModalStore = defineStore('modal', {
    state: () => {
        return {
            data: null,
            type: null
        }
    },
    actions: {
        close() {
            this.type = null
            this.data = null
        },
        openAvatar() {
            this.type = CONSTANTS.MODAL_TYPES.AVATAR
        },
        openBrief(brief) {
            this.type = CONSTANTS.MODAL_TYPES.BRIEF

            const textDate = localizedDate(brief.date, {
                day: 'numeric',
                month: 'long',
                weekday: 'long',
                year: 'numeric'
            })

            this.data = {
                ...brief,
                context: brief.employee.full_name,
                date: textDate,
                vitals: {
                    emotional_state: {
                        average: brief.vitals.emotional_state.data.score
                    },
                    title: `${i18n.global.t('sentiment_for')} ${textDate}`
                }
            }
        },
        openCustomBrief({employees, end_date, start_date, teams}) {
            const localizedDateOptions = {day: 'numeric', month: 'long', year: 'numeric'}
            const textStartDate = localizedDate(new Date(start_date), localizedDateOptions, false)
            const textEndDate = localizedDate(new Date(end_date), localizedDateOptions, false)

            const textTeams = teams.join(', ')

            const employeesText = employees.length > 1 ? `${i18n.global.t('brief.custom_team')} ${textTeams}` : useAuthStore().employees[employees[0]].name
            this.data = {
                context: employeesText,
                custom: true,
                date: i18n.global.t('brief.date').replace(':start', textStartDate).replace(':end', textEndDate),
            }

            this.type = CONSTANTS.MODAL_TYPES.BRIEF

            const apiPayload = {employees, end_date, start_date}
            baseApi.post(CONSTANTS.API_ENDPOINTS.CUSTOM_BRIEF.PREVIEW, apiPayload)
                .then(({data}) => {
                    if (this.type !== null) {
                        const vitals = {}

                        let emotional_state = []
                        const periodAverage = (data.emotional_state.reduce((carry, vital) => {
                            if (!emotional_state[vital.date]) {
                                emotional_state[vital.date] = vital
                            } else {
                                emotional_state[vital.date].data.score = (emotional_state[vital.date].data.score + vital.data.score) / 2
                            }

                            return carry + vital.data.score
                        }, 0) / data.emotional_state.length).toFixed(2)

                        vitals.emotional_state = {
                            average: parseFloat(periodAverage),
                            dataset: Object.values(emotional_state).reverse()
                        }

                        vitals.title = i18n.global.t('average_sentiment_for')
                            .replace(':start', textStartDate)
                            .replace(':end', textEndDate)

                        this.data.vitals = vitals
                    }
                })
                .catch(exception => {
                    //todo handle exception
                    console.error(exception)
                })

            baseApi.post(CONSTANTS.API_ENDPOINTS.CUSTOM_BRIEF.GENERATE, apiPayload)
                .then(({data}) => {
                    if (this.type !== null) {
                        this.data.summaries = data.summaries
                        this.data.title = data.title
                        this.data.vitals.sentiments = data.vitals.sentiments
                    }
                })
                .catch(exception => {
                    //todo handle exception
                    console.error(exception)
                })
        },
        openEmployeeUpdate(employee) {
            this.type = CONSTANTS.MODAL_TYPES.EMPLOYEE_UPDATE
            this.data = employee
        },
        openSettings() {
            this.type = CONSTANTS.MODAL_TYPES.SETTINGS
        },
        openTeamUpdate(team) {
            this.type = CONSTANTS.MODAL_TYPES.TEAM_UPDATE
            this.data = team
        }
    }
})