export default {
    employee: {
        all: 'Tous les employés',
        selected: {
            plural: '[x] employés sélectionnés',
            singular: '1 employé sélectionné'
        }
    },
    team: {
        all: 'Toutes les équipes',
        selected: {
            plural: '[x] équipes sélectionnées',
            singular: '1 équipe sélectionnée'
        }
    }
}