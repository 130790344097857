<template>
  <nav class="absolute bottom-0 left-1/2 -translate-x-1/2 h-full flex items-center justify-center">
    <LangRouterLink :to="link.to" v-for="(link, linkIndex) in links" :key="`employee_menu_${linkIndex}`" class="px-4.5 relative h-full flex items-center">
      <span>{{ link.label }}</span>
      <span class="w-full h-2 bg-lime-900 block absolute bottom-0 left-0 rounded-t-lg opacity-0 transition-opacity active-mark"/>
    </LangRouterLink>
  </nav>
</template>

<script>
import LangRouterLink from '@/components/helpers/LangRouterLink.vue'

export default {
  name: 'EmployeeNavigation',
  components: {LangRouterLink},
  computed: {
    links() {
      return [
        {
          label: this.$t('chat_with_keylime'),
          to: {name: this.$consts.ROUTES.EMPLOYEE.STAND_UP}
        },
        {
          label: this.$t('squeezes'),
          to: {name: this.$consts.ROUTES.EMPLOYEE.SQUEEZES}
        }
      ]
    }
  }
}
</script>