import axios from 'axios'

const aiApi = axios.create({
    baseURL: import.meta.env.VITE_APP_AI_API_URL,
    headers: { "x-api-key": import.meta.env.VITE_APP_AI_API_AUTH_KEY },
    withCredentials: true
})

const baseApi = axios.create({
    baseURL: import.meta.env.VITE_APP_API_URL,
    headers: { Accept: 'application/json' },
    withCredentials: true,
    withXSRFToken: true
})

export {
    aiApi,
    baseApi
}