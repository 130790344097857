export default {
    all_teams: 'Toutes les équipes',
    avatar: {
        description: 'Adoptez l\'ambiance KeyLime en soumettant une photo de face et bien éclairée de votre visage. Nous l\'analyserons et vous enverrons directement aux Keys!',
        edit: 'Créer mon avatar',
        regen: 'Générer une autre version',
        your: 'Votre Avatar KeyLime'
    },
    average_sentiment_for: 'Sentiment moyen du : début à : fin',
    back: 'Retour',
    back_to_employees: 'Retour aux Employés',
    birthdate: 'Date de Naissance',
    blockers: 'Bloquants',
    brief: {
        categories: {
            challenges: 'Défis',
            projects: 'Mises à jour des projets',
            sentiments: 'Sentiment'
        },
        custom: 'Résumé personnalisé',
        custom_team: 'Pour tous les employés de',
        date: 'Du :start au :end',
        export: 'Exporter le résumé',
        generate: 'Générer un résumé personnalisé',
        listen: 'Écouter le résumé',
        stop: 'Arrêtez d\'écouter',
        view: 'Consulter le résumé'
    },
    briefs: 'Résumés',
    bugs: 'Bogues',
    cancel: 'Annuler',
    challenges: {
        extreme: 'Défi extrême',
        high: 'Défi élevé',
        low: 'Défi faible',
        medium: 'Défi moyen',
        moderate: 'Défi modéré'
    },
    challenges_heatmap: 'Carte thermique des défis',
    chat_with_keylime: 'Discuter avec KeyLime',
    client: 'Client',
    close_window: 'Fermer la fenêtre',
    coming_soon: 'À venir...',
    complete_stand_up: 'Compléter mon Stand-Up',
    compared_yesterday: ':comparison comparé à hier',
    completion_and_submission: 'L\'employé a mis :completion_time pour répondre à KeyPoint et a soumis :submission_time.',
    dark_mode: {
        switch: {
            dark: 'Passer au mode sombre',
            light: 'Passer au mode lumineux'
        }
    },
    dates: {
        close: 'Fermer les dates',
        select: 'Sélectionnez des dates'
    },
    desktop_only: 'Version mobile à venir',
    done: 'Complété',
    email: 'Courriel',
    employee: {
        add: 'Ajouter un employé',
        see_vitals: {
            plural: 'Voir les vitaux d\'employés',
            singular: 'Voir les vitaux de l\'employé'
        },
        switch: 'Passez en vue employé',
        team_notice: 'L\'affiliation à l\'équipe d\'un employé ne peut être mise à jour que via son profil.',
        update: 'Modifier le profil de l\'employé'
    },
    employees: 'Employés',
    emotional_state: 'État émotionel',
    energy: 'Énergie',
    engagement_quality: 'Qualité d\'engagement',
    errors: {
        date: 'La date doit respecter le format YYYY/MM/DD',
        max_2mb: 'Fichier trop lourd. (Max 2 Mo)',
        special_characters: 'La valeur du champ ne doit pas contenir de caractères spéciaux.',
        server_update: 'Vos modifications n\'ont pas pu être sauvegardée. Veuillez réessayer ultérieurement.'
    },
    find_something: 'Trouver quelque chose',
    first_name: 'Prénom',
    focus: 'Focus',
    full_name: 'Nom complet',
    generate_brief: 'Générer un résumé',
    hub: 'Hub',
    interactions: 'Interactions',
    job: 'Poste',
    last_name: 'Nom',
    latest_briefs: 'Derniers résumés',
    listen: 'Listen',
    login: 'Connexion',
    logout: 'Déconnexion',
    main_menu: 'Menu Principal',
    manager: {
        switch: 'Passez en vue gestionnaire'
    },
    monthly_score: 'Moyenne du mois',
    name: 'Nom',
    no_briefs: 'Aucun résumé ne correspond aux critères de recherche.',
    ongoing_tasks: 'Tâches en cours',
    or: 'ou',
    password: 'Mot de passe',
    period: {
        daily: {
            comparison: ':comparison depuis hier'
        },
        monthly: {
            comparison: ':comparison depuis le mois passé'
        },
        weekly: {
            comparison: ':comparison depuis la semaine passée'
        },
    },
    photo: {
        choose: 'Choisissez une photo',
        drag_drop: 'Glissez-déposez ou sélectionnez une photo depuis votre ordinateur',
        retake: 'Reprendre une photo',
        take: 'Prendre une photo',
        use: 'Utilisez cette photo'
    },
    powered_by_ai: 'Propulsé par IA',
    productivity: 'Productivité',
    reset: 'Réinitialiser',
    see_all: 'Voir tout',
    sentiment: 'Sentiment',
    sentiment_for: 'Sentiment pour le',
    settings: 'Réglages',
    short_description: 'Description courte',
    snap: 'Prendre la photo',
    sort: 'Trier',
    squeeze: {
        explore_all: 'Explore All Squeezes',
        export: 'Exporter le Squeeze',
        title: 'Dernier Squeeze - :date'
    },
    squeezes: 'Squeezes',
    stand_up_completed: 'Votre stand-up quotidien a été complété. Revenez demain pour répondre à un autre !',
    stress_level: 'Niveau de stress',
    submit: {
        changes: 'Soumettre les changements',
        photo: 'Soumettre la photo',
    },
    summary: {
        long: 'Version complète',
        short: 'Version abrégée'
    },
    team: {
        add: 'Ajouter une équipe',
        label: 'Équipe',
        notice: 'Veuillez noter que les données affichées pour les KeyVitals des équipes sont dérivées de la moyenne des données individuelles de chaque employé, plutôt que des données spécifiques de l\'équipe.',
        see_vitals: {
            plural: 'Voir les vitaux d\'équipes',
            singular: 'Voir les vitaux de l\'équipe'
        },
        update: 'Modifier le profil d\'équipe'
    },
    teams: 'Équipes',
    tech: 'Tech',
    today: 'aujourd\'hui',
    todays_keylime: 'KeyLime du jour',
    todays_score: 'Score du jour',
    todays_sentiment: 'Sentiment du jour',
    todo: 'À faire',
    view_switch: {
        employee: 'Passer en vue Employé',
        manager: 'Passer en vue Gestionnaire'
    },
    weekly_score: 'Moyenne de la semaine',
    welcome: 'Bienvenue',
    work_email_address: 'Adresse courriel professionnelle',
    work_title: 'Poste',
    x_days_ago: 'd\'il y a :X jours',
    x_days_ago_sentiment: 'Sentiment d\'il y a X jours',
    yesterday: 'Hier',
    yesterdays_sentiment: 'Sentiment d\'hier',
    you: 'Vous'
}