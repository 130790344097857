import {defineStore} from 'pinia'
import CONSTANTS from '@/config/CONSTANTS.js'

export const useLayoutStore = defineStore('layout', {
    state: () => {
        return {
            vitals_order: null
        }
    },
    actions: {
        init() {
            const storageValue = localStorage.getItem(CONSTANTS.LOCAL_STORAGE.LAYOUT.VITALS_ORDER)
            if (storageValue) this.vitals_order = JSON.parse(storageValue)
        },
        saveVitalsOrder(order) {
            localStorage.setItem(CONSTANTS.LOCAL_STORAGE.LAYOUT.VITALS_ORDER, JSON.stringify(order))
        }
    }
})