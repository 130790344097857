<template>
  <main class="h-[100dvh] w-full relative overflow-hidden grid grid-rows-[5.75rem,1fr] grid-cols-[1fr]">
    <TopNavigation/>

    <slot/>

    <BackgroundGradientSpot class="left-[-6vw] top-[-7vw]"/>
    <BackgroundGradientSpot class="left-[-10vw] top-[6vw]"/>
    <BackgroundGradientSpot class="left-[-5vw] top-[18vw]"/>
    <BackgroundGradientSpot class="left-[-7vw] top-[29vw]"/>

    <BackgroundGradientSpot class="right-[-14vw] top-[-9vw]"/>
    <BackgroundGradientSpot class="right-[-12vw] top-[4vw]"/>
    <BackgroundGradientSpot class="right-[-14vw] top-[16vw]"/>
    <BackgroundGradientSpot class="right-[-12vw] top-[26vw]"/>
    <BackgroundGradientSpot class="right-[-15vw] top-[37vw]"/>

    <BackgroundGradientSpot class="left-[3vw] top-[-12vw]"/>
    <BackgroundGradientSpot class="left-[15vw] top-[-9vw]"/>
    <BackgroundGradientSpot class="left-[28vw] top-[-12vw]"/>
    <BackgroundGradientSpot class="left-[42vw] top-[-9vw]"/>
    <BackgroundGradientSpot class="left-[54vw] top-[-12vw]"/>
    <BackgroundGradientSpot class="left-[68vw] top-[-9vw]"/>
    <BackgroundGradientSpot class="left-[80vw] top-[-12vw]"/>
  </main>
</template>

<script>
import {SideNavigation, TopNavigation} from '@/components/navigation/index.js'
import {BackgroundGradientSpot} from '@/components/ui/index.js'

export default {
  name: 'DesktopLayout',
  components: {SideNavigation, BackgroundGradientSpot, TopNavigation}
}
</script>