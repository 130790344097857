import {defineStore} from 'pinia'
import CONSTANTS from '@/config/CONSTANTS.js'

export const useNavigationStore = defineStore('navigation', {
    state: () => {
        return {
            collapsed: false,
            collapse_duration: 500
        }
    },
    actions: {
        init() {
            const storageValue = localStorage.getItem(CONSTANTS.LOCAL_STORAGE.NAVIGATION)
            if(storageValue) this.collapsed = storageValue === 'true'
        },
        save() {
            localStorage.setItem(CONSTANTS.LOCAL_STORAGE.NAVIGATION, this.collapsed.toString())
        },
        toggleCollapsed() {
            this.collapsed = !this.collapsed
            this.save()
        }
    }
})