export default {
    employee: {
        all: 'All Employees',
        selected: {
            plural: '[x] Employees Selected',
            singular: '1 Employee Selected'
        }
    },
    team: {
        all: 'All Teams',
        selected: {
            plural: '[x] Teams Selected',
            singular: '1 Team Selected'
        }
    }
}