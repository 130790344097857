<template>
  <div class="grid grid-cols-[1fr_max-content_1fr] gap-4 h-full overflow-hidden">
    <IconTextButton :label="$t('back')" icon="arrow_thin" secondary class="[&>svg]:-rotate-90" @click="$emit('close')"/>
    <div class="mx-auto aspect-square h-90 relative overflow-hidden">
      <video src="" ref="video" muted autoplay class="w-full h-full block object-cover"/>
      <Transition name="fade" mode="out-in" component="div">
        <img v-if="image" :src="image" alt="" ref="snapshot" class="w-full h-full object-cover absolute top-0 left-0">
      </Transition>
    </div>
    <div class="flex flex-col items-stretch ml-auto gap-2 [&>*]:w-full min-w-42">
      <Transition name="fade-fast" mode="out-in">
        <IconTextButton v-if="!image" :label="$t('snap')" icon="photo" @click.stop="snap"/>
        <IconTextButton v-else :label="$t('photo.retake')" icon="reset" @click.stop="reset" secondary/>
      </Transition>
      <Transition name="fade-fast">
        <button v-if="image" class="button" @click.stop="save">{{ $t('photo.use') }}</button>
      </Transition>
    </div>
  </div>
</template>

<script>
import {IconTextButton} from '@/components/small'

export default {
  name: 'AvatarSnapshot',
  components: {IconTextButton},
  props: {
    video: {
      type: MediaStream,
      default: null
    }
  },
  data() {
    return {
      image: null,
    }
  },
  methods: {
    initVideo() {
      this.$refs.video.srcObject = this.video
    },
    reset() {
      this.image = null
    },
    save() {
      this.$emit('save', this.image)
      this.reset()
    },
    snap() {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      canvas.width = this.$refs.video.videoWidth
      canvas.height = this.$refs.video.videoHeight
      context.drawImage(this.$refs.video, 0, 0, canvas.width, canvas.height)
      this.image = canvas.toDataURL('image/png')
    }
  },
  mounted() {
    this.initVideo()
  },
  emits: ['close', 'save']
}
</script>