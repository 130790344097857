import {defineStore} from 'pinia'

export const useVoiceStore = defineStore('voice', {
    state: () => {
        return {
            enabled: false,
            instance: null,
            speakingId: null
        }
    },
    actions: {
        init() {
            try {
                this.instance = new SpeechSynthesisUtterance()
                this.enabled = true
                this.instance.addEventListener("end", this.reset)
            } catch (exception) {
                this.enabled = false
            }
        },
        reset() {
            speechSynthesis.cancel()
            this.speakingId = null
        },
        speak(text, id) {
            if (this.enabled) {
                this.reset()

                this.instance.text = text
                speechSynthesis.speak(this.instance)
                this.speakingId = id
            }
        }
    }
})