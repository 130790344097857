<template>
  <DesktopLayout>
    <RouterView v-slot="{Component}">
      <Transition name="fade" mode="out-in">
        <component :is="Component"/>
      </Transition>
    </RouterView>
  </DesktopLayout>
</template>

<script>
import {DesktopLayout} from '@/components/templates'

export default {
  name: 'Auth',
  components: {DesktopLayout}
}
</script>