<template>
  <div>
    <label :for="name" :class="['mb-2.5 block', {'sr-only': noLabel}]">{{ label }}</label>
    <div class="relative">
      <input :autocomplete="autoComplete" :id="name" :name="name" :placeholder="placeholder" :type="type"
             :value="modelValue.value"
             :class="['px-2 py-1 h-10 block w-full', {'rounded-sm bg-lime-50 text-gray-900': lime, 'border border-red-900 focus:outline-red-900': !noErrors && !modelValue.valid},
           {'bg-white-900 border border-light_gray_200 rounded-lg text-sm': !lime}, {'pr-16': limit}]"
             :maxlength="limit"
             @input="input">
      <p v-if="!noErrors && !modelValue.valid" class="text-red-900 text-xs mt-1 ml-2 selection:bg-red-300">{{ error }}</p>
      <p v-if="limit" class="absolute text-sm right-5 top-[0.65rem] text-light_gray-900 opacity-60 dark:text-gray-800 dark:opacity-80">
        {{ `${modelValue.value.length}/${limit}` }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    autoComplete: {
      type: String,
      default: null
    },
    label: {
      type: String,
      required: true
    },
    limit: {
      type: Number,
      default: null
    },
    modelValue: {
      required: true
    },
    name: {
      type: String,
      required: true
    },
    noErrors: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'string',
      validator: value => ['email', 'password', 'string'].includes(value)
    },
    lime: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    error() {
      switch (this.name) {
        case 'birthdate':
          return this.$t('errors.date')
        default:
          return this.$t('errors.special_characters')
      }
    }
  },
  methods: {
    input(event) {
      this.$emit('update:modelValue', {valid: this.validate(event.target.value), value: event.target.value})
    },
    validate(value) {
      switch (this.name) {
        case 'birthdate':
          return this.$consts.REGEX.BIRTHDATE.test(value)
        case 'email':
          return this.$consts.REGEX.EMAIL.test(value)
        case 'first_name':
        case 'last_name':
        case 'work_title':
          return this.$consts.REGEX.NAME.test(value)
        case 'job':
          return this.$consts.REGEX.STRING_WITH_NUMBERS.test(value)
        case 'team_name':
          return this.$consts.REGEX.TEAM.NAME.test(value)
        case 'team_short_description':
          return this.$consts.REGEX.TEAM.SHORT_DESCRIPTION.test(value)
        default:
          return this.$consts.REGEX.PASSWORD.test(value)
      }
    }
  },
  emits: ['update:modelValue']
}
</script>