<template>
  <div class="w-full flex justify-center items-center">
    <button v-for="period in periods" :key="`period-${period}`" @click="$emit('update', period)"
            :class="['w-6 aspect-square flex justify-center items-center text-sm rounded-md transition-colors', {'bg-light_gray-200 dark:bg-gray-600': active === period}]">
      {{ periodLetter(period) }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'PeriodFilter',
  props: {
    active: {
      type: String,
      required: true,
      validator: value => ['daily', 'weekly', 'monthly'].includes(value)
    },
    periods: {
      type: Array,
      default: ['daily', 'weekly', 'monthly']
    }
  },
  methods: {
    periodLetter(period) {
      return {
        daily: this.$i18n.locale === 'fr' ? 'J' : 'T',
        monthly: 'M',
        weekly: this.$i18n.locale === 'fr' ? 'S' : 'W'
      }[period]
    }
  },
  emits: ['update']
}
</script>