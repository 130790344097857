<template>
  <button @mouseenter="showTooltip" @mouseleave="hideTooltip" :disabled="disabled" ref="button"
          class="aspect-square w-7 flex items-center justify-center rounded transition-all hover:bg-light_gray-200 dark:hover:bg-black relative disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent">
    <TooltipHover v-if="tooltip" :tooltip="tooltip" :bottom="bottom" fixed/>
    <Icon :id="icon" class="text-lg mt-0.25"/>
  </button>
</template>

<script>
import {Icon} from '@/components/helpers'
import TooltipHover from '@/components/vitals/small/TooltipHover.vue'

export default {
  name: 'IconButton',
  components: {TooltipHover, Icon},
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true
    },
    help: {
      type: String,
      required: true
    },
    bottom: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      delay: 50,
      tooltip: null,
      visible: false
    }
  },
  methods: {
    hideTooltip() {
      setTimeout(() => {
        this.tooltip.visible = false
      }, this.delay)
    },
    showTooltip() {
      if (!this.disabled && this.$refs.button) {
        setTimeout(() => {
          const rect = this.$refs.button.getBoundingClientRect()

          this.tooltip = {
            text: this.help,
            visible: true,
            x: `${this.bottom ? rect.right : rect.left + (rect.width / 2)}px`,
            y: `${this.bottom ? rect.bottom + (rect.height * 0.4) : rect.top - (rect.height * 1.6)}px`,
          }
        }, this.delay)
      }
    }
  }
}
</script>