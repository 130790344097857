<template>
  <Transition name="fade">
    <div v-if="visible" class="p-4 w-full flex gap-3 bg-beige rounded-lg">
      <Icon id="info" class="shrink-0 text-orange-icon"/>
      <p class="text-xs text-light_gray-900">{{ text }}</p>
      <button v-if="storageKey" class="shrink-0 h-max ml-auto" @click.stop="close">
        <Icon id="close" class="text-xs"/>
      </button>
    </div>
  </Transition>
</template>

<script>
import {Icon} from '@/components/helpers'

export const CLOSED_KEY = 'closed'

export default {
  name: 'Notice',
  components: {Icon},
  props: {
    storageKey: {
      type: String,
      default: null
    },
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    close() {
      this.visible = false
      localStorage.setItem(this.storageKey, CLOSED_KEY)
    }
  },
  beforeMount() {
    if (!this.storageKey || localStorage.getItem(this.storageKey) !== CLOSED_KEY) {
      this.visible = true
    }
  }
}
</script>