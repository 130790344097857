<template>
  <div>
    <h2 class="text-3xl">{{ $t('avatar.edit') }}</h2>
    <p class="leading-5 mt-5">{{ $t('avatar.description') }}</p>
    <Transition name="fade" mode="out-in" component="div" class="h-90 mt-8 mb-11">
      <div v-if="videoStream === null" class="grid grid-cols-2 gap-4">
        <div class="flex flex-col items-center gap-4">
          <div class="grow bg-light_gray-100 rounded-lg w-full p-5">
            <label for="image"
                   class="relative flex flex-col justify-center items-center gap-4 border-dashed border-2 border-light_gray-800 h-full rounded p-9 cursor-pointer overflow-hidden"
                   @dragover.prevent @drop.prevent="drop" @dragenter="hover = true" @dragleave="hover = false">
              <input type="file" alt="" id="image" name="image" class="hidden" ref="input" @change="fileChange"
                     accept="image/png, image/jpeg">
              <Icon id="pictures" class="text-[32px] text-light_gray-900 pointer-events-none"/>
              <span class="text-light_gray-900 max-w-52 text-center leading-6 pointer-events-none">{{
                  $t('photo.drag_drop')
                }}</span>
              <span :class="['button button-secondary mt-1', {'pointer-events-none': hover}]">{{
                  $t('photo.choose')
                }}</span>
              <Transition name="fade">
              <span v-if="hover"
                    class="pointer-events-none absolute bg-light_gray-900 w-full h-full opacity-60 rounded border-dashed border-2 border-light_gray-800 z-20"></span>
              </Transition>
              <Transition name="slide-down">
              <span v-if="error" @click.prevent.stop="error = null"
                    class="absolute bottom-0 left-0 w-full bg-red-900 py-2 px-3 flex justify-between items-center text-white-900">
                <span class="text-sm">{{ error }}</span>
                <Icon id="close" class="text-[10px]"/>
              </span>
              </Transition>
            </label>
          </div>
          <template v-if="canSnapshot">
            <p>{{ $t('or') }}</p>
            <IconTextButton :label="$t('photo.take')" icon="photo" class="w-full" secondary
                            @click.stop="openSnapshot"/>
          </template>
        </div>
        <div class="bg-light_gray-100 rounded-lg flex flex-col justify-center items-center py-9 gap-2.5">
          <p>{{ $t('avatar.your') }}</p>
          <Transition name="fade-fast" mode="out-in">
            <img :src="image" alt="" class="w-48 aspect-square object-cover" :key="image">
          </Transition>
          <button
              class="flex gap-1 items-center group transition-opacity disabled:cursor-not-allowed disabled:opacity-60"
              :disabled="!canRegen" @click.stop="regen">
            <Icon id="reset"
                  class="transition-transform group-hover:rotate-[360deg] group-disabled:rotate-0 duration-[400ms]"/>
            <span>{{ $t('avatar.regen') }}</span>
          </button>
        </div>
      </div>
      <AvatarSnapshot v-else @close="closeSnapshot" @save="saveSnapshot" :video="videoStream"/>
    </Transition>
    <div class="ml-auto flex justify-end gap-4 mt-2.5">
      <button type="button" class="button button-secondary" @click.stop="$modal.close">{{ $t('cancel') }}</button>
      <button type="button" class="button" :disabled="!canSubmit" @click.stop="submit">
        {{ $t('submit.photo') }}
      </button>
    </div>
  </div>
</template>

<script>
import {AvatarSnapshot} from '@/components/blocks'
import {Icon} from '@/components/helpers'
import {IconTextButton} from '@/components/small'

export default {
  name: 'AvatarEditModal',
  components: {AvatarSnapshot, Icon, IconTextButton},
  data() {
    return {
      error: null,
      hover: false,
      image: this.$auth.user.avatar,
      videoStream: null
    }
  },
  computed: {
    canRegen() {
      return this.image !== this.$auth.user.avatar
    },
    canSnapshot() {
      return navigator.mediaDevices !== undefined
    },
    canSubmit() {
      return this.image !== this.$auth.user.avatar
    }
  },
  methods: {
    closeSnapshot() {
      this.videoStream.getTracks().forEach(track => track.stop())

      this.videoStream = null
    },
    drop(event) {
      this.hover = false
      this.$refs.input.files = event.dataTransfer.files

      this.validateSize(event.dataTransfer.files[0])
    },
    fileChange() {
      this.validateSize(this.$refs.input.files[0])
    },
    async openSnapshot() {
      try {
        const videoStream = await navigator.mediaDevices.getUserMedia({video: true, audio: false})

        if (videoStream) {
          this.videoStream = videoStream
        }
      } catch {
      }
    },
    regen() {
      this.image = this.$auth.user.avatar
    },
    saveSnapshot(newImage) {
      this.image = newImage
      this.closeSnapshot()
    },
    submit() {
      this.$auth.updateAvatar(this.$refs.input.files[0])
      this.$modal.close()
    },
    validateSize(file) {
      this.error = null

      if (file.size > 2_000_000) {
        this.error = this.$t('errors.max_2mb')
        this.$refs.input.value = null
      } else {
        this.image = URL.createObjectURL(file)
      }
    }
  },
  watch: {
    '$modal.type'() {
      if (this.$modal.type === null && this.videoStream) {
        this.closeSnapshot()
      }
    }
  }
}
</script>