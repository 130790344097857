import {defineStore} from 'pinia'
import {baseApi} from '@/extensions/http/index.js'
import {useAIStore} from '@/stores/ai.js'
import {session} from '@/extensions/session/index.js'
import CONSTANTS from '@/config/CONSTANTS.js'

import router from '@/router'

export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            briefs: [],
            employees: {},
            logged_in: false,
            ready: false,
            user: null
        }
    },
    actions: {
        async getBriefs() {
            if (!this.briefs.length) {
                try {
                    const {data} = await baseApi.get(CONSTANTS.API_ENDPOINTS.BRIEF.INDEX)
                    this.briefs = data
                } catch (exception) {
                    //TODO implement exception handler
                    console.log(exception)
                }
            }

            return this.briefs
        },
        async getEmployee(id) {
            if (this.employees[id]) return this.employees[id]

            try {
                const {data} = await baseApi.get(CONSTANTS.API_ENDPOINTS.EMPLOYEE.SHOW.replace(':id', id))
                this.employees[data.id] = data
                return data
            } catch (exception) {
                //TODO implement exception handler
                console.log(exception)
            }
            return null
        },
        async login(payload) {
            try {
                await baseApi.get(CONSTANTS.API_ENDPOINTS.AUTH.SANCTUM_CSRF)
                const {data} = await baseApi.post(CONSTANTS.API_ENDPOINTS.AUTH.LOGIN, payload)

                if (data) this.setUser(data)
            } catch (exception) {
                //TODO implement exception handler
                console.error(exception)
            }
        },
        async logout() {
            try {
                this.ready = false
                await baseApi.post(CONSTANTS.API_ENDPOINTS.AUTH.LOGOUT)

                this.logged_in = false
                session.deleteExpiration()
                await router.push({name: CONSTANTS.ROUTES.AUTH.LOGIN})
            } catch (exception) {
                //TODO implement exception handler
                console.error(exception)
            }
            this.ready = true
        },
        async reconnect() {
            try {
                if (!session.isExpired()) {
                    await baseApi.get(CONSTANTS.API_ENDPOINTS.AUTH.SANCTUM_CSRF)
                    const {data} = await baseApi.get(CONSTANTS.API_ENDPOINTS.USER.SHOW)

                    if (data) this.setUser(data)
                }
            } catch (exception) {
                //TODO implement exception handler
                console.error(exception)
            }

            this.ready = true
        },
        setUser(user) {
            this.user = user
            this.logged_in = true
            useAIStore().initAgents()
            session.saveExpiration()
        },
        async updateAvatar(image) {
            try {
                //todo fix this
                this.user.avatar = URL.createObjectURL(image)
                await baseApi.post(CONSTANTS.API_ENDPOINTS.USER.AVATAR_UPDATE, {image: image}, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                })
            } catch (exception) {
                //TODO implement exception handler
                console.error(exception)
            }
        }
    }
})