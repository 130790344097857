<template>
  <p :class="classes">{{ word }}</p>
</template>

<script>
export default {
  name: 'SentimentWord',
  props: {
    color: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    word: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      initiated: false
    }
  },
  computed: {
    classes() {
      const baseColorClasses = ['bg-light_gray-200', 'text-black', 'border-light_gray-200']
      const sentimentColorClasses = [`bg-${this.color}-50`, `text-${this.color}-900`, `border-${this.color}-700`]

      return ['capitalize rounded-md border transition-colors duration-500', {
        'py-2.5 px-4 text-[15px]': this.index === 0,
        'py-2 px-3 text-[14px]': this.index === 1,
        'py-1 px-2.5 text-[13px]': this.index >= 2,
      }].concat(this.initiated ? sentimentColorClasses : baseColorClasses)
    }
  },
  watch: {
    'color': {
      handler() {
        if (!this.initiated) setTimeout(() => this.initiated = true, 500)
      },
      immediate: true
    }
  }
}
</script>