<template>
  <div :class="['w-8 aspect-square rounded-full transition-[background-color] duration-1000 [&_path]:transition-all [&_path]:duration-500', `bg-${initiated ? color + '-700' : 'light_gray-200'}`]">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 34.08 34.08"
         :key="color">
      <path id="smiley_right_eye" data-name="smiley_right_eye" :d="smiley.right_eye.orange" :stroke="traitsColor"
            fill="none" stroke-linecap="round" stroke-width="2">
        <animate attributeName="d"
                 attributeType="XML"
                 :from="smiley.right_eye[last_color]"
                 :to="smiley.right_eye[color]"
                 begin="0s"
                 :dur="settings.duration"
                 fill="freeze"
        />
      </path>

      <path id="smiley_left_eye" data-name="smiley_left_eye" :d="smiley.left_eye.orange" :stroke="traitsColor"
            fill="none" stroke-linecap="round" stroke-width="2">
        <animate attributeName="d"
                 attributeType="XML"
                 :from="smiley.left_eye[last_color]"
                 :to="smiley.left_eye[color]"
                 begin="0s"
                 :dur="settings.duration"
                 fill="freeze"
        />
      </path>

      <path id="smiley_mouth" data-name="smiley_mouth" :d="smiley.mouth.orange" :stroke="traitsColor"
            fill="none" stroke-linecap="round" stroke-width="2">
        <animate attributeName="d"
                 attributeType="XML"
                 :from="smiley.mouth[last_color]"
                 :to="smiley.mouth[color]"
                 begin="0s"
                 :dur="settings.duration"
                 fill="freeze"
        />
      </path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Smiley',
  props: {
    score: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      settings: {
        duration: '1s'
      },
      initiated: false,
      last_color: 'orange',
      color: 'orange',
      smiley: {
        left_eye: {
          lime: 'M16.3,13.17c0-1.27-1.03-2.3-2.3-2.3s-2.3,1.03-2.3,2.3',
          yellow: 'M13.37,11.78c-.47.49-1.23,1.29-2.13,1.7s-.99.45-1.65.65-2.81.65',
          orange: 'M13.68,14.06c-.63.18-1.03.3-2.3.3s-1.74-.17-2.3-.3',
          red: 'M13.53,13.78c0,1.27-1.03,2.3-2.3,2.3s-2.3-1.03-2.3-2.3',
          pink: 'M9.53,15.02c0,1.1-.89,1.99-1.99,1.99s-1.99-.89-1.99-1.99'
        },
        mouth: {
          lime: 'M14.14,18.24c1.42,1.25,3.37,2.02,5.51,2.02s4.11-.77,5.52-2.02',
          yellow: 'M12.05,20.43c.84,1.71,2.75,2.9,4.96,2.9s4.12-1.19,4.96-2.9',
          orange: 'M13,22.56c1.63-.09,1.82-.13,4.03-.13s2.6.04,4.03.13',
          red: 'M12.07,24.61c.84-1.71,2.75-2.9,4.96-2.9s4.12,1.19,4.96,2.9',
          pink: 'M9.63,24c1.03-.68,2-.89,3.21-.46s2.16,1.59,2.8,2.74'
        },
        right_eye: {
          lime: 'M27.91,13.17c0-1.27-1.03-2.3-2.3-2.3s-2.3,1.03-2.3,2.3',
          yellow: 'M25.25,14.12c-.61.06-1.7-.17-2.58-.55s-1.77-1.05-2.36-1.79',
          orange: 'M25.12,14.06c-.56.16-1.03.3-2.3.3s-1.66-.15-2.3-.3',
          red: 'M25.15,13.78c0,1.27-1.03,2.3-2.3,2.3s-2.3-1.03-2.3-2.3',
          pink: 'M19.56,15.02c0,1.1-.89,1.99-1.99,1.99s-1.99-.89-1.99-1.99'
        }
      }
    }
  },
  computed: {
    traitsColor() {
      return this.initiated ? this.$tailwind.theme.colors.light_gray[50] : this.$tailwind.theme.colors.black
    }
  },
  watch: {
    'score': {
      handler() {
        if (this.score) {
          this.last_color = this.color
          this.color = this.$color(this.score)
          if(!this.initiated) setTimeout(() => this.initiated = true, 500)
        }
      },
      immediate: true
    }
  }
}
</script>