<template>
  <header class="flex items-center justify-center gap-5 shrink-0 w-24">
    <Logo/>
  </header>
</template>

<script>
import {Logo} from '@/components/small'

export default {
  name: 'PageHeader',
  components: {Logo}
}
</script>