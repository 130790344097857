<template>
  <div>
    <h2 class="text-3xl">{{ $t('team.update') }}</h2>
    <form class="w-full flex flex-col mt-8 gap-5" @submit.prevent="submit">
      <FormInput name="team_name" v-model="form.fields.name" :label="$t('name')" :limit="45"/>
      <FormInput name="team_short_description" v-model="form.fields.short_description" :label="$t('short_description')"
                 :limit="75"/>
      <div>
        <p class="mb-2.5">{{ $t('employees') }}</p>
        <Notice :text="$t('employee.team_notice')"/>
        <div class="flex mt-4 gap-2.5 flex-wrap">
          <p v-for="employee in $modal.data.employees" :key="`team-update-employee-${employee.id}`"
             class="text-sm px-3 py-2 bg-light_gray-100 rounded-md text-light_gray-900 dark:bg-gray-700 dark:text-white-900">{{ employee.name }}</p>
        </div>
      </div>
      <div class="ml-auto flex gap-4 mt-2.5">
        <button type="button" class="button button-secondary" @click.stop="$modal.close">{{ $t('cancel') }}</button>
        <button type="submit" class="button" :disabled="!valid || form.server.submitted">{{
            $t('submit.changes')
          }}
        </button>
      </div>
      <p v-if="form.server.error" class="ml-auto text-xs text-red-900">{{ $t('errors.server_update') }}</p>
    </form>
  </div>
</template>

<script>
import {FormInput} from '@/components/form/inputs'
import {FormBaseMixin} from '@/components/form/mixins'
import {Notice} from '@/components/small'
import {baseApi} from '@/extensions/http'

export default {
  name: 'TeamUpdateModal',
  mixins: [FormBaseMixin],
  components: {Notice, FormInput},
  data() {
    return {
      form: {
        fields: {
          name: {
            valid: true,
            value: this.$modal.data.name
          },
          short_description: {
            valid: true,
            value: this.$modal.data.short_description
          }
        },
        server: {
          submitted: false,
          error: null
        }
      }
    }
  },
  methods: {
    async handle() {
      const {data} = await baseApi.put(this.$consts.API_ENDPOINTS.TEAM.UPDATE.replace(':id', this.$modal.data.id), {
        name: this.form.fields.name.value,
        short_description: this.form.fields.short_description.value
      })
      this.$auth.user.manager.teams[data.id] = data
    }
  }
}
</script>