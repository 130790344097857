<template>
  <div class="max-w-[60dvw]">
    <h2 class="text-3xl">{{ $t('employee.update') }}</h2>
    <form action="" @submit.prevent="submit" class="flex flex-wrap gap-4 mt-8 [&>div]:w-[calc((100%-1rem)/2)]">
      <FormInput name="first_name" v-model="form.fields.first_name" :label="$t('first_name')"/>
      <FormInput name="last_name" v-model="form.fields.last_name" :label="$t('last_name')"/>
      <FormInput name="job" v-model="form.fields.job" :label="$t('work_title')"/>
      <FormSelect name="team" v-model="form.fields.team" :label="$t('team.label')" type="teams"/>
      <FormInput name="birthdate" v-model="form.fields.birthdate" :label="`${$t('birthdate')} (YYYY/MM/DD)`"/>
      <FormInput name="email" v-model="form.fields.email" :label="$t('work_email_address')" type="email"/>
      <div class="ml-auto flex justify-end gap-4 mt-2.5">
        <button type="button" class="button button-secondary" @click.stop="$modal.close">{{ $t('cancel') }}</button>
        <button type="submit" class="button" :disabled="!valid || form.server.submitted">{{
            $t('submit.changes')
          }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {FormBaseMixin} from '@/components/form/mixins'
import {FormInput, FormSelect} from '@/components/form/inputs'
import {baseApi} from '@/extensions/http/index.js'

export default {
  name: 'EmployeeUpdateModal',
  mixins: [FormBaseMixin],
  components: {FormInput, FormSelect},
  data() {
    return {
      form: {
        fields: {
          birthdate: {
            value: this.$modal.data.birthdate,
            valid: true
          },
          email: {
            value: this.$modal.data.email,
            valid: true
          },
          first_name: {
            value: this.$modal.data.first_name,
            valid: true
          },
          job: {
            value: this.$modal.data.job,
            valid: true
          },
          last_name: {
            value: this.$modal.data.last_name,
            valid: true
          },
          team: {
            value: this.$modal.data.team.id,
            valid: true
          }
        },
        server: {
          submitted: false,
          error: null
        }
      }
    }
  },
  methods: {
    async handle() {
      const formData = Object.entries(this.form.fields).reduce((carry, [key, {value}]) => {
        carry[key] = value
        return carry
      }, {})

      const {data} = await baseApi.put(this.$consts.API_ENDPOINTS.EMPLOYEE.UPDATE.replace(':id', this.$modal.data.id), formData)

      Object.values(this.$auth.user.manager.teams).forEach(team => {
        if (team.id === data.team.id) {
          team.employees[data.id] = data
        } else if (team.employees[data.id]) {
          delete team.employees[data.id]
        }
      })
    }
  }
}
</script>