<template>
  <button class="flex gap-2.5 items-center" @click.stop="$modal.openAvatar">
    <img :src="$auth.user.avatar" alt="" class="w-10 aspect-square rounded-md overflow-hidden object-cover" :key="`employee-profile-avatar-${$auth.user.avatar}`">
    <span>
      <span class="block text-left">{{ $auth.user.first_name }}</span>
      <span class="text-xs mt-0.25">
        <span>{{ $t('todays_keylime') }}</span>
        <span>{{ ` - ` }}</span>
        <span class="font-bold">{{ $auth.user.employee.stand_up.completed ? $t('done') : $t('todo') }}</span>
      </span>
    </span>
  </button>
</template>

<script>
export default {
  name: 'EmployeeProfile'
}
</script>