export default {
    API_ENDPOINTS: {
        AUTH: {
            LOGIN: '/login',
            LOGOUT: '/logout',
            SANCTUM_CSRF: '/sanctum/csrf-cookie'
        },
        BRIEF: {
            INDEX: '/api/briefs'
        },
        CUSTOM_BRIEF: {
            GENERATE: '/api/briefs/custom/generate',
            PREVIEW: '/api/briefs/custom/preview'
        },
        EMPLOYEE: {
            SHOW: '/api/employees/:id',
            UPDATE: '/api/employees/:id'
        },
        STAND_UP: {
            STORE: '/api/stand-ups'
        },
        USER: {
            AVATAR_UPDATE: '/api/me/avatar',
            SHOW: '/api/me',
        },
        TEAM: {
            UPDATE: '/api/teams/:id'
        }
    },
    LOCAL_STORAGE: {
        LAYOUT: {
            VITALS_ORDER: 'vitals_order'
        },
        NAVIGATION: 'collapsed',
        TEAMS_NOTICE: 'teams_notice'
    },
    MODAL_TYPES: {
        AVATAR: 'avatar',
        BRIEF: 'brief',
        EMPLOYEE_UPDATE: 'employee_update',
        SETTINGS: 'settings',
        TEAM_UPDATE: 'team_update'
    },
    REGEX: {
        BIRTHDATE: /^\d{4}\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])$/,
        EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        NAME: /^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,255}$/,
        NUMBER: /^\d+$/,
        PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,255}$/,
        PHONE: /^\d{10}$/,
        SIX_DIGITS: /^\d{6}$/,
        STRING_WITH_NUMBERS: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,255}$/,
        TEAM: {
            NAME: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,45}$/,
            SHORT_DESCRIPTION: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,75}$/
        },
        TEXT: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,1000}$/
    },
    ROUTES: {
        AUTH: {
            BASE: 'auth',
            LOGIN: 'auth.login'
        },
        EMPLOYEE: {
            HOME: 'employee.home',
            SQUEEZE: 'employee.squeeze',
            SQUEEZES: 'employee.squeezes',
            STAND_UP: 'employee.stand-up'
        },
        MANAGER: {
            BRIEFS: 'manager.briefs',
            EMPLOYEE: 'manager.employee',
            EMPLOYEES: 'manager.employees',
            HOME: 'manager.home',
            HUB: 'manager.hub',
            TEAM: 'manager.team',
            TEAMS: 'manager.teams'
        }
    },
    QUERY: {
        END_DATE: 'to',
        START_DATE: 'from',
        TEAM: 't'
    }
}