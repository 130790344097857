<template>
  <h2 class="text-3xl mb-6">{{ $t('settings') }}</h2>
  <button @click.stop="$preferences.darkMode.toggle()" class="mt-4">
    <span class="dark:rotate-180 inline-block transition-transform">🌗</span>
    <span class="ml-1">{{ $t(`dark_mode.switch.${$preferences.darkMode.enabled ? 'light' : 'dark'}`) }}</span>
  </button>
  <button @click.stop="$modal.openAvatar" class="block mt-4">
    <span>🥷</span>
    <span class="ml-1">{{ $t('avatar.edit') }}</span>
  </button>

  <button class="button w-max ml-auto px-4 mt-10" @click.stop="logout()">{{ $t('logout') }}</button>
</template>

<script>
export default {
  name: 'SettingsModal',
  methods: {
    async logout() {
      await this.$auth.logout()
      await this.$modal.close()
    }
  }
}
</script>