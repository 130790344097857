<template>
  <Transition v-if="$voice.enabled" name="fade-fast" mode="out-in">
    <IconButton v-if="$voice.speakingId === id" :help="$t('brief.stop')" icon="ear_stop"
                @click.stop="$voice.reset()" :disabled="disabled"/>
    <IconButton v-else :help="$t('brief.listen')" icon="ear" @click.stop="$voice.speak(content, id)" :disabled="disabled"/>
  </Transition>
</template>

<script>
import {IconButton} from '@/components/small'

export default {
  name: 'Voice',
  components: {IconButton},
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  }
}
</script>