<template>
  <nav class="px-6 col-start-1 col-end-1 row-start-1 row-end-1 pb-6 pt-1 flex flex-col justify-between relative z-0">
    <div>
      <p class="text-sm h-5 overflow-hidden">
        <span class="block w-max" v-if="!$preferences.navigation.collapsed">{{ $t('main_menu') }}</span>
      </p>
      <div class="flex flex-col mt-4 gap-1">
        <LangRouterLink :to="link.to" v-for="(link, linkIndex) in links" :key="`main-menu-${linkIndex}`"
                        :class="['menu__item', {'menu__item-collapsed': $preferences.navigation.collapsed}]"
                        :title="link.label">
          <Icon :id="link.icon" class="-mt-0.5 shrink-0"/>
          <span v-if="!$preferences.navigation.collapsed">{{ link.label }}</span>
        </LangRouterLink>
        <button :class="['menu__item', {'menu__item-collapsed': $preferences.navigation.collapsed}]"
                @click.stop="$modal.openSettings" :title="$t('settings')">
          <Icon id="settings" class="-mt-0.5 shrink-0"/>
          <span v-if="!$preferences.navigation.collapsed">{{ this.$t('settings') }}</span>
        </button>
      </div>
    </div>
    <ManagerProfile :collapsed="collapsed"/>
    <button @click="$preferences.navigation.toggleCollapsed()"
            class="absolute flex items-center top-[0.625rem] right-0 w-max h-[calc(100%-0.625rem)] after:content-[''] after:block after:w-[0.125rem] after:h-full after:bg-lime-900 after:absolute after:right-0 after:top-0 after:z-10 transition-opacity opacity-0 hover:opacity-100">
      <span class="w-6 aspect-square rounded-l-md bg-lime-900 flex items-center justify-center">
        <Icon id="arrow_top" :class="['text-[0.5rem] dark:text-gray-900', {'-scale-x-100 ': !$preferences.navigation.collapsed}]"/>
      </span>
    </button>
  </nav>
</template>

<script>
import {Icon, LangRouterLink} from '@/components/helpers'
import {ManagerProfile} from '@/components/navigation/blocks'

export default {
  name: 'SideNavigation',
  components: {Icon, LangRouterLink, ManagerProfile},
  data() {
    return {
      collapsed: false,
    }
  },
  computed: {
    links() {
      return [
        {
          icon: 'house',
          label: this.$t('hub'),
          to: {name: this.$consts.ROUTES.MANAGER.HUB}
        },
        {
          icon: 'user',
          label: this.$t('employees'),
          to: {name: this.$consts.ROUTES.MANAGER.EMPLOYEES}
        },
        {
          icon: 'brief',
          label: this.$t('briefs'),
          to: {name: this.$consts.ROUTES.MANAGER.BRIEFS}
        },
        {
          icon: 'team',
          label: this.$t('teams'),
          to: {name: this.$consts.ROUTES.MANAGER.TEAMS}
        }
      ]
    }
  },
  emits: ['toggle'],
  watch: {
    '$preferences.navigation.collapsed'() {
      if (this.$preferences.navigation.collapsed) {
        return setTimeout(() => this.collapsed = true, this.$preferences.navigation.collapse_duration)
      }

      this.collapsed = false
    }
  }
}
</script>