<template>
  <div class="h-[calc(90dvh-5rem)] grid grid-rows-[max-content_1fr] w-[calc(90dvw-5rem)]">
    <h2 class="text-3xl mb-4">{{ $modal.data.title ?? $t('brief.custom') }}</h2>
    <div class="grid grid-rows-[max-content_1fr_max-content] gap-7">
      <div class="bg-light_gray-100 text-light_gray-900 p-5 rounded-lg">
        <p class="mb-1">{{ $modal.data.date }}</p>
        <p>{{ $modal.data.context }}</p>
      </div>
      <div class="grid grid-cols-[65%_35%] gap-4 h-[calc(90dvh-18rem)]">
        <div class="shadow-panel rounded-2xl relative grid grid-rows-[max-content_1fr] h-full">
          <div :class="[headerClasses, 'sticky top-0 bg-white-900 z-10']">
            <div class="flex gap-7 h-full">
              <button v-for="type in ['short', 'long']" :key="`${type}_summary`"
                      class="h-full relative disabled:cursor-not-allowed disabled:opacity-50 transition-opacity"
                      @click.stop="mode = type" :disabled="content === null">
                <span>{{ $t(`summary.${type}`) }}</span>
                <span
                    :class="['absolute bottom-0 left-0 w-full h-2 rounded-t-lg transition-[background-color]', {'bg-lime-app': type === mode}]"/>
              </button>
            </div>
            <VoiceControls :content="$helpers.stripTags(content)" id="custom_brief" :disabled="content === null"/>
          </div>
          <Transition name="fade" mode="out-in">
            <div v-if="content" class=" text-sm max-h-[calc(90dvh-21.75rem)]">
              <div class="p-7 h-full overflow-auto">
                <p v-if="mode === 'long'" class="leading-5">{{ content }}</p>
                <ShortSummary v-if="mode === 'short'" :content="content" />
              </div>
            </div>
            <div v-else class="flex items-center justify-center w-full grow overflow-auto">
              <div class="spinner"/>
            </div>
          </Transition>
        </div>
        <div class="shadow-panel rounded-2xl flex flex-col">
          <div :class="headerClasses">
            <p>{{ $t('emotional_state') }}</p>
            <div class="flex gap-2.5">
              <IconButton :help="teamVitalsLabel" icon="team" @click.stop="goTo(teamVitalsRoute)"/>
              <IconButton :help="employeeVitalsLabel" icon="user" @click.stop="goTo(employeeVitalsRoute)"/>
            </div>
          </div>
          <Transition name="fade" mode="out-in">
            <div v-if="$modal.data.vitals" class="p-6">
              <p class="mb-3 text-xs text-light_gray-900">{{ $modal.data.vitals.title }}</p>
              <div class="flex gap-2.5 items-start">
                <Smiley v-if="$modal.data.vitals.emotional_state.average"
                        :score="$modal.data.vitals.emotional_state.average"/>
                <div v-if="$modal.data.vitals.sentiments" class="flex items-end gap-1.5">
                  <SentimentWord :word="word" :index="wordIndex" :color="color"
                                 v-for="(word, wordIndex) in $modal.data.vitals.sentiments.data"
                                 :key="`brief-sentiment-${wordIndex}`" class="w-max max-w-full"/>
                </div>
              </div>
              <LinearGraph v-if="$modal.data.vitals.emotional_state.dataset" :dataset="$modal.data.vitals.emotional_state.dataset" class="w-full" no-average no-filters/>
            </div>
            <div v-else class="flex justify-center items-center grow">
              <div class="spinner"/>
            </div>
          </Transition>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-end ml-auto gap-4">
      <button class="button button-secondary" @click.stop="$modal.close">{{ $t('close_window') }}</button>
      <IconTextButton :label="$t('brief.export')" icon="download" @click.stop="exportBrief" disabled/>
    </div>
  </div>
</template>

<script>
import {ShortSummary} from '@/components/blocks'
import {IconButton, IconTextButton, VoiceControls} from '@/components/small'
import {SentimentWord, Smiley} from '@/components/vitals/small'
import LinearGraph from '@/components/vitals/graphs/LinearGraph.vue'

export default {
  name: 'BriefModal',
  components: {LinearGraph, IconButton, IconTextButton, SentimentWord, ShortSummary, Smiley, VoiceControls},
  data() {
    return {
      headerClasses: 'h-15 flex items-center justify-between px-6 border-light_gray-200 border-b rounded-t-2xl shrink-0',
      mode: 'short'
    }
  },
  computed: {
    color() {
      return this.$color(this.$modal.data.vitals.emotional_state.average)
    },
    content() {
      if (!this.$modal.data.summaries) return null

      return this.$modal.data.summaries[this.mode]
    },
    employeeVitalsLabel() {
      return this.$t(`employee.see_vitals.${this.$modal.data.custom ? 'plural' : 'singular'}`)
    },
    employeeVitalsRoute() {
      return this.$modal.data.custom
          ? {name: this.$consts.ROUTES.MANAGER.EMPLOYEES}
          : {name: this.$consts.ROUTES.MANAGER.EMPLOYEE, params: {id: this.$modal.data.employee.id}}
    },
    teamVitalsLabel() {
      return this.$t(`team.see_vitals.${this.$modal.data.custom ? 'plural' : 'singular'}`)
    },
    teamVitalsRoute() {
      return this.$modal.data.custom
          ? {name: this.$consts.ROUTES.MANAGER.TEAMS}
          : {name: this.$consts.ROUTES.MANAGER.TEAM, params: {id: this.$modal.data.employee.team}}
    }
  },
  methods: {
    exportBrief() {
      //todo implement this
    },
    async goTo(to) {
      await this.$router.push(to)
      this.$modal.close()
    }
  }
}
</script>