<template>
  <main id="main" class="flex justify-center items-center h-full flex-col">
    <PageHeader/>
    <Transition name="fade-fast" mode="out-in">
      <form v-if="!form.submitted" action="" @submit.prevent="submit" class="flex flex-col gap-4 mt-6 w-64">
        <FormInput v-model="form.email" auto-complete="email" :label="$t('email')" name="email" no-label no-errors
                   :placeholder="$t('email')" type="email" lime/>
        <FormInput v-model="form.password" auto-complete="password" :label="$t('password')" name="password"
                   no-label no-errors :placeholder="$t('password')" type="password" lime/>
        <button class="button w-full" type="submit" :disabled="!valid">{{ $t('login') }}</button>
      </form>
      <div v-else class="min-h-[9.5rem] flex justify-center items-center">
        <div class="spinner"/>
      </div>
    </Transition>
  </main>
</template>

<script>
import {PageHeader} from '@/components/blocks'
import {FormInput} from '@/components/form/inputs'

export default {
  name: 'AuthLogin',
  components: {FormInput, PageHeader},
  data() {
    return {
      form: {
        email: {
          valid: false,
          value: ''
        },
        password: {
          valid: false,
          value: ''
        },
        submitted: false
      }
    }
  },
  computed: {
    valid() {
      return this.form.email.valid && this.form.password.valid
    }
  },
  methods: {
    async submit() {
      if (this.valid) {
        try {
          this.form.submitted = true
          await this.$auth.login({email: this.form.email.value, password: this.form.password.value})
          this.$router.push({name: this.$consts.ROUTES.EMPLOYEE.STAND_UP})
        } catch (exception) {
          //todo handle exception
          this.form.submitted = false
        }
      }
    }
  },
  mounted() {
    if(this.$auth.logged_in) this.$router.push({name: this.$consts.ROUTES.EMPLOYEE.STAND_UP})
  }
}
</script>