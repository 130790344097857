import defaultTheme from 'tailwindcss/defaultTheme'

/** @type {import('tailwindcss').Config} */
export default {
    darkMode: 'selector',
    content: [
        './src/**/*.vue'
    ],
    safelist: [
        {pattern: /bg-(gray|lime|orange|pink|red|yellow)-(0|50|100|200|300|400|500|600|700|800|900)/},
        {pattern: /border-(gray|lime|orange|pink|red|yellow)-(0|50|100|200|300|400|500|600|700|800|900)/},
        {pattern: /text-(gray|lime|orange|pink|red|yellow)-(0|50|100|200|300|400|500|600|700|800|900)/},
        'duration-500'
    ],
    theme: {
        extend: {
            borderRadius: {
                DEFAULT: '0.3125rem',
                md: '0.5rem',
                lg: '0.625rem',
                '2xl': '0.9375rem'
            },
            boxShadow: {
                panel: '0px 0px 16px #00000014;'
            },
            colors: {
                beige: '#FFF6D1',
                black: '#232323',
                light_gray: {
                    900: '#5C5C5C',
                    700: '#64646414',
                    600: '#64646421',
                    300: '#E7E7E7',
                    200: '#ECECEC',
                    100: '#FAFAFA',
                    50: '#FFF4F1'
                },
                lime: {
                    app: '#D3F36B',
                    900: '#A5D019',
                    800: '#A5D019E6',
                    700: '#A5D019CC',
                    600: '#A5D019B3',
                    500: '#A5D01999',
                    400: '#A5D01980',
                    300: '#A5D01966',
                    200: '#A5D0194D',
                    100: '#A5D01933',
                    50: '#A5D0191A',
                    0: '#A5D01900',
                },
                modal: '#00000080',
                orange: {
                    icon: '#DB6E00',
                    900: '#F89A2B',
                    800: '#F89A2BE6',
                    700: '#F89A2BCC',
                    600: '#F89A2BB3',
                    500: '#F89A2B99',
                    400: '#F89A2B80',
                    300: '#F89A2B66',
                    200: '#F89A2B4D',
                    100: '#F89A2B33',
                    50: '#F89A2B1A',
                    0: '#F89A2B00',
                },
                pink: {
                    900: '#EB4A79',
                    800: '#EB4A79E6',
                    700: '#EB4A79CC',
                    600: '#EB4A79B3',
                    500: '#EB4A7999',
                    400: '#EB4A7980',
                    300: '#EB4A7966',
                    200: '#EB4A794D',
                    100: '#EB4A7933',
                    50: '#EB4A791A',
                    0: '#EB4A7900',
                },
                red: {
                    900: '#F0714A',
                    800: '#F0714AE6',
                    700: '#F0714ACC',
                    600: '#F0714AB3',
                    500: '#F0714A99',
                    400: '#F0714A80',
                    300: '#F0714A66',
                    200: '#F0714A4D',
                    100: '#F0714A33',
                    50: '#F0714A1A',
                    0: '#F0714A00',
                },
                turquoise: {
                    900: '#23BEB7',
                    800: '#23BEB7E6',
                    700: '#23BEB7CC',
                    600: '#23BEB7B3',
                    500: '#23BEB799',
                    400: '#23BEB780',
                    300: '#23BEB766',
                    200: '#23BEB74D',
                    100: '#23BEB733',
                    50: '#23BEB71A',
                    0: '#23BEB700',
                },
                yellow: {
                    900: '#F8CC13',
                    800: '#F8CC13E6',
                    700: '#F8CC13CC',
                    600: '#F8CC13B3',
                    500: '#F8CC1399',
                    400: '#F8CC1380',
                    300: '#F8CC1366',
                    200: '#F8CC134D',
                    100: '#F8CC1333',
                    50: '#F8CC131A',
                    0: '#F8CC1300',
                },
                white: {
                    900: '#FFFFFF',
                    800: '#FFFFFFE6',
                    700: '#FFFFFFCC',
                    600: '#FFFFFFB3',
                    500: '#FFFFFF99',
                    400: '#FFFFFF80',
                    300: '#FFFFFF66',
                    200: '#FFFFFF4D',
                    100: '#FFFFFF33',
                    50: '#FFFFFF1A',
                    0: '#F8CC1300',
                }
            },
            fontFamily: {
                'sans': ['Albert Sans', ...defaultTheme.fontFamily.sans]
            },
            fontSize: {
                tn: '0.5rem'
            },
            spacing: {
                0.25: '0.0625rem',
                4.5: '1.125rem',
                15: '3.75rem',
                17: '4.25rem',
                30: '7.5rem',
                35: '8.75rem',
                42: '10.5rem',
                90: '22.5rem',
                144: '36rem',
                232: '58rem'
            },
            transitionDuration: {
                '250': '250ms'
            }
        },
    }
}

