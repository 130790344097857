import LOCALE from '@/config/locale.js'
import CONSTANTS from '@/config/CONSTANTS.js'
import {helpers} from '@/helpers/index.js'

import {createApp} from 'vue'
import {createPinia} from 'pinia'
import '@/style.css'

import 'v-calendar/style.css'
import {setupCalendar} from 'v-calendar'

import App from '@/App.vue'
import i18n from '@/i18n'
import langRouter from '@/extensions/langRouter'
import router from '@/router'

import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../tailwind.config.js'

import {
    useAIStore,
    useAuthStore,
    useDarkModeStore,
    useLayoutStore,
    useModalStore,
    useNavigationStore,
    useVoiceStore
} from '@/stores/index.js'

import {Vue3Mq} from 'vue3-mq'

import axios from 'axios'

const app = createApp(App)

app.use(createPinia())
    .use(i18n)
    .use(router)
    .use(langRouter, LOCALE.DEFAULT)
    .use(setupCalendar, {})

app.config.globalProperties.$consts = CONSTANTS
app.config.globalProperties.$helpers = helpers

app.config.globalProperties.$tailwind = resolveConfig(tailwindConfig)

app.config.globalProperties.$auth = useAuthStore()
app.config.globalProperties.$ai = useAIStore()
app.config.globalProperties.$preferences = {
    darkMode: useDarkModeStore(),
    layout: useLayoutStore(),
    navigation: useNavigationStore()
}
app.config.globalProperties.$modal = useModalStore()
app.config.globalProperties.$voice = useVoiceStore()

app.config.globalProperties.$color = score => {
    if (!score) return 'light_gray'
    if (score > 0.8) return 'lime'
    if (score > 0.6) return 'yellow'
    if (score > 0.4) return 'orange'
    if (score > 0.2) return 'red'
    return 'pink'
}

app.use(Vue3Mq, {
    breakpoints: {
        xs: 0,
        sm: 566,
        md: 1024,
        lg: 1360,
        xl: 1440
    }
})

const {data} = await axios('/icons.svg')
document.body.insertAdjacentHTML('afterbegin', data)

app.mount('#app')
