export default {
    data() {
        return {
            tooltip: {
                text: null,
                visible: false,
                x: 0,
                y: 0
            }
        }
    }
}