<template>
  <h1 class="sr-only">Keylime</h1>
  <Transition name="fade" mode="out-in">
    <div v-if="!$auth.ready" class="w-full h-full flex justify-center items-center">
      <div class="spinner"/>
    </div>
    <div v-else class="w-full h-full flex justify-center items-center">
      <RouterView v-if="mq.mdPlus"/>
      <p v-else>{{ $t('desktop_only') }}</p>
      <Modal/>
    </div>
  </Transition>
</template>

<script>
import {Auth, AuthLogin} from '@/views/Auth'
import Modal from '@/components/modal/Modal.vue'
import {DesktopLayout} from '@/components/templates'

export default {
  name: 'App',
  inject: ['mq'],
  components: {Auth, AuthLogin, DesktopLayout, Modal},
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.$auth.reconnect()
      if (!this.$auth.logged_in) this.$router.push({name: this.$consts.ROUTES.AUTH.LOGIN})
      this.$preferences.darkMode.init()
      this.$preferences.layout.init()
      this.$preferences.navigation.init()
      this.$voice.init()
    }
  }
}
</script>
