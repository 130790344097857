<template>
  <div class="flex flex-col gap-6">
    <div v-for="category in ['sentiments', 'projects', 'challenges']" :key="`brief-category-${category}`">
      <h3 class="text-light_gray-900 dark:text-white-700">{{ $t(`brief.categories.${category}`) }}</h3>
      <ul v-if="content[category].length" class="mt-3 flex flex-col gap-3">
        <li v-for="(item, itemIndex) in content[category]" :key="`brief-category-${category}-item-${itemIndex}`"
            class="relative pl-4">
          <span class="w-1.5 aspect-square rounded-full bg-lime-app block absolute left-0 top-1"/>
          <span>{{ item }}</span>
        </li>
      </ul>
      <p v-else class="mt-2 text-xs">N/A</p>
    </div>
  </div>
</template>

<script>
import {HasContentMixin} from '@/mixins'

export default {
  name: 'ShortSummary',
  mixins: [HasContentMixin]
}
</script>