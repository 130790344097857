<template>
  <p :class="['py-1 px-2.5 rounded-md w-max border min-w-14 text-center dark:text-white-700', `bg-${color}-50`, `text-${color}-900`, `border-${color}-400`]">
    {{ score }}
  </p>
</template>

<script>
export default {
  name: 'Score',
  props: {
    score: {
      type: Number,
      required: true
    }
  },
  computed: {
    color() {
      return this.$color(this.score)
    }
  }
}
</script>