import {defineStore} from 'pinia'
import {RemoteRunnable} from '@langchain/core/runnables/remote'
import {useAuthStore} from '@/stores/auth.js'

export const useAIStore = defineStore('ai', {
    state: () => {
        return {
            stand_up_agent: null
        }
    },
    actions: {
        initAgents() {
            const auth = useAuthStore()

            const date = new Date()
            date.setUTCHours(0,0,0,0)

            this.stand_up_agent = new RemoteRunnable({
                url: `${import.meta.env.VITE_APP_AI_API_URL}/stand-up`, options: {
                    headers: {
                        'x-api-key': import.meta.env.VITE_APP_AI_API_AUTH_KEY,
                        'x-session-id': `user${auth.user.id}_stand-up_${date.toISOString()}`
                    }
                }
            })
        }
    }
})