<template>
  <div class="flex flex-col justify-between gap-2.5 mt-2 relative" @mouseleave="tooltip.visible = false">
    <div v-if="!noAverage" class="flex gap-2.5 text-sm items-center">
      <Score :score="parseInt(averages[period])"/>
      <p class="text-light_gray-900 dark:text-white-700">{{ labels[period] }}</p>
    </div>
    <div class="bg-light_gray-100 dark:bg-gray-600 rounded-lg  relative" @mouseenter="tooltip.visible = true">
      <div class="absolute flex justify-center items-center h-full w-full gap-[24%] overflow-hidden">
        <span v-for="i in 3" :key="i" class="w-[1px] h-full bg-light_gray-200 opacity-25"/>
      </div>
      <div class="absolute flex justify-center items-center h-full w-full flex-col gap-[14%] overflow-hidden">
        <span v-for="i in 6" :key="i" class="h-[1px] w-full bg-light_gray-200 opacity-25"/>
      </div>
      <canvas ref="canvas" class="relative z-10"></canvas>
    </div>
    <TooltipHover :tooltip="tooltip"/>
    <PeriodFilter v-if="!noFilters" :active="period" :periods="periods" @update="updatePeriod"/>
  </div>
</template>

<script>
import {CategoryScale, Chart, Filler, LinearScale, LineController, LineElement, PointElement, Tooltip} from 'chart.js'
import {HasDatasetMixin, HasPeriodFilterMixin, HasTooltipMixin} from '@/components/vitals/mixins'
import {PeriodFilter, Score, TooltipHover} from '@/components/vitals/small'

export default {
  name: 'LinearGraph',
  mixins: [HasDatasetMixin, HasPeriodFilterMixin, HasTooltipMixin],
  chart: null, //Needs to be non-reactive to prevent a bug https://github.com/chartjs/Chart.js/issues/8970
  components: {PeriodFilter, Score, TooltipHover},
  props: {
    noAverage: {
      type: Boolean,
      default: false
    },
    noFilters: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      averages: {
        monthly: 0,
        weekly: 0
      },
      labels: {
        monthly: this.$t('monthly_score'),
        weekly: this.$t('weekly_score')
      },
      period: 'weekly',
      periods: ['weekly', 'monthly'],
    }
  },
  computed: {
    color() {
      return this.$color(this.averages[this.period])
    },
    filteredDataset() {
      if (this.period === 'weekly') {
        return Array.from(this.dataset).slice(0, 5)
      }

      return this.dataset
    }
  },
  mounted() {
    if(this.noFilters) this.period = 'monthly'

    // Setup
    Chart.register(CategoryScale, Filler, LinearScale, LineController, LineElement, PointElement, Tooltip)

    // Config
    Chart.defaults.elements.line.tension = 0.4
    Chart.defaults.elements.point.radius = 0
    Chart.defaults.elements.point.hitRadius = 5

    const reversedDataset = Array.from(this.filteredDataset).reverse()

    this.chart = new Chart(
        this.$refs.canvas,
        {
          type: 'line',
          data: {
            labels: reversedDataset.map(({date}) => date),
            datasets: [
              {
                label: null,
                data: reversedDataset.map(({data}) => data.score),
                borderColor: this.borderColor(),
                borderWidth: 4,
                backgroundColor: this.backgroundColor,
                fill: true,
                pointHoverRadius: 5
              }
            ]
          },
          options: {
            interaction: {
              intersect: false,
              axis: 'x',
              mode: 'nearest',
            },
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              x: {
                display: false,
              },
              y: {
                min: 0,
                max: 1,
                display: false
              }
            },
            plugins: {
              tooltip: {
                enabled: false,
                position: 'nearest',
                external: this.externalTooltipHandler
              }
            }
          }
        }
    )
  },
  methods: {
    backgroundColor(context) {
      if (!context.chart.chartArea) {
        return
      }

      const {ctx, chartArea: {top, bottom}} = context.chart
      const gradientBackground = ctx.createLinearGradient(0, top, 1, bottom)
      gradientBackground.addColorStop(0, this.$tailwind.theme.colors[this.color][200])
      gradientBackground.addColorStop(1, this.$tailwind.theme.colors[this.color][0])

      return gradientBackground
    },
    borderColor() {
      return this.$tailwind.theme.colors[this.color][900]
    },
    externalTooltipHandler(context) {
      const {chart, tooltip} = context

      const score = tooltip.body[0].lines[0]
      const date = this.$helpers.localizedDate(tooltip.title[0], {
        day: 'numeric',
        month: 'long',
        weekday: 'short'
      })

      const yOffset = this.noAverage ? -34 : 0

      this.tooltip.text = `${date} - ${score}`
      this.tooltip.x = `${Math.floor(tooltip.caretX)}px`
      this.tooltip.y = `${Math.floor(tooltip.caretY + yOffset)}px`
    }
  },
  watch: {
    filteredDataset: {
      handler() {
        this.averages.weekly = (Array.from(this.filteredDataset)
            .reduce((previous, current) => previous + current.data.score, 0) / 5)
            .toFixed(2)
        this.averages.monthly = (Array.from(this.filteredDataset)
            .reduce((previous, current) => previous + current.data.score, 0) / this.dataset.length)
            .toFixed(2)

        if (this.chart) {
          const dataset = Array.from(this.filteredDataset).reverse()

          this.chart.data.labels = dataset.map(({date}) => date)
          this.chart.data.datasets[0].data = dataset.map(({data}) => data.score)
          this.chart.data.datasets[0].borderColor = this.borderColor()
          this.chart.data.datasets[0].backgroundColor = this.backgroundColor
          this.chart.update()
        }
      },
      immediate: true
    }
  }
}
</script>