import {defineStore} from 'pinia'

export const LOCAL_STORAGE_KEY= 'dark_mode'
export const DARK_MODE_CLASS= 'dark'

export const useDarkModeStore = defineStore('darkMode', {
    state: () => {
        return {
            enabled: false,
        }
    },
    actions: {
        init() {
          this.load()
          this.update()
        },
        load() {
            const storageValue = localStorage.getItem(LOCAL_STORAGE_KEY)
            if(storageValue) this.enabled = storageValue === 'true'
            else this.enabled = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        },
        save() {
            localStorage.setItem(LOCAL_STORAGE_KEY, this.enabled)
        },
        toggle() {
            this.enabled = !this.enabled
            this.save()
            this.update()
        },
        update() {
            if(this.enabled && !document.body.classList.contains(DARK_MODE_CLASS)) {
                document.body.classList.add(DARK_MODE_CLASS)
            } else if(!this.enabled && document.body.classList.contains(DARK_MODE_CLASS)) {
                document.body.classList.remove(DARK_MODE_CLASS)
            }
        }
    }
})