import LOCALE from '@/config/locale.js'

import {createRouter, createWebHistory} from 'vue-router'
import {generateRoutes} from '@/extensions/langRouter'
import CONSTANTS from '@/config/CONSTANTS.js'
import {useAuthStore} from '@/stores/auth.js'

const routes = [
    {
        path: '',
        name: CONSTANTS.ROUTES.AUTH.BASE,
        component: () => import('@/views/Auth/Auth.vue'),
        children: [
            {
                name: CONSTANTS.ROUTES.EMPLOYEE.HOME,
                path: '',
                component: () => import('@/views/Employee/Home.vue'),
                redirect: to => {
                    if (to.name === CONSTANTS.ROUTES.EMPLOYEE.HOME) return {name: CONSTANTS.ROUTES.EMPLOYEE.STAND_UP}
                },
                children: [
                    {
                        path: '/stand-up',
                        name: CONSTANTS.ROUTES.EMPLOYEE.STAND_UP,
                        component: () => import('@/views/Employee/StandUp.vue')
                    },
                    {
                        path: 'squeezes',
                        children: [
                            {
                                path: '',
                                name: CONSTANTS.ROUTES.EMPLOYEE.SQUEEZES,
                                redirect: to => {
                                    const user = useAuthStore()?.user
                                    const id = user ? Object.values(user.employee.squeezes)[0].id : '1'

                                    return {name: CONSTANTS.ROUTES.EMPLOYEE.SQUEEZE, params: {id: id}}
                                }
                            },
                            {
                                path: ':id',
                                name: CONSTANTS.ROUTES.EMPLOYEE.SQUEEZE,
                                component: () => import('@/views/Employee/Squeeze.vue'),
                                props: true
                            }
                        ]
                    }
                ]
            },
            {
                name: CONSTANTS.ROUTES.MANAGER.HOME,
                path: 'manager',
                component: () => import('@/views/Manager/Home.vue'),
                children: [
                    {
                        path: 'hub',
                        name: CONSTANTS.ROUTES.MANAGER.HUB,
                        component: () => import('@/views/Manager/Hub.vue')
                    },
                    {
                        path: 'briefs',
                        children: [
                            {
                                path: '',
                                name: CONSTANTS.ROUTES.MANAGER.BRIEFS,
                                component: () => import('@/views/Manager/Briefs.vue')
                            }
                        ]

                    },
                    {
                        path: 'employees',
                        children: [
                            {
                                path: '',
                                name: CONSTANTS.ROUTES.MANAGER.EMPLOYEES,
                                component: () => import('@/views/Manager/Employees.vue')
                            },
                            {
                                path: ':id',
                                name: CONSTANTS.ROUTES.MANAGER.EMPLOYEE,
                                component: () => import('@/views/Manager/Employee.vue'),
                                props: true
                            },
                        ]
                    },
                    {
                        path: 'teams',
                        children: [
                            {
                                path: '',
                                name: CONSTANTS.ROUTES.MANAGER.TEAMS,
                                component: () => import('@/views/Manager/Teams.vue')
                            },
                            {
                                path: ':id',
                                name: CONSTANTS.ROUTES.MANAGER.TEAM,
                                component: () => import('@/views/Manager/Team.vue'),
                                props: true
                            },
                        ]
                    }
                ]
            }
        ],
    },
    {
        path: '/login',
        name: CONSTANTS.ROUTES.AUTH.LOGIN,
        component: () => import('@/views/Auth/AuthLogin.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        redirect: to => {
            return {name: CONSTANTS.ROUTES.AUTH.LOGIN}
        }
    }
]

const localizedRoutes = generateRoutes(routes, LOCALE.DEFAULT, LOCALE.FALLBACK)

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes: routes,
    scrollBehavior(to) {
        return to.hash
            ? {el: to.hash, behavior: 'smooth'}
            : {top: 0, behavior: 'smooth'}
    }
})

export default router