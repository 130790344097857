<template>
  <div>
    <label :for="name" :class="['mb-2.5 block', {'sr-only': noLabel}]">{{ label }}</label>
    <select :name="name" id="name" class="bg-white-900 border border-light_gray_200 rounded-lg text-sm px-2 py-1 h-10 block w-full" @change="change">
      <option :value="option.key" v-for="option in options" :key="`${name}-option-${option.key}`"
              :selected="modelValue.value.toString() === option.key">{{
          option.value
        }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'FormSelect',
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      required: true
    },
    name: {
      type: String,
      required: true
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true,
      validator: value => 'teams' === value
    }
  },
  computed: {
    options() {
      switch (this.type) {
        default:
          return Object.values(this.$auth.user.manager.teams).map(({id, name}) => {
            return {
              key: id.toString(),
              value: name
            }
          })
      }
    }
  },
  methods: {
    change(event) {
      this.$emit('update:modelValue', {valid: this.options.some(({key}) => key === event.target.value), value: event.target.value})
    }
  },
  emits: ['update:modelValue']
}
</script>