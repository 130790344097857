<template>
  <nav class="w-full flex justify-between items-center p-6 col-start-1 col-end-3 row-start-1 row-end-1 relative z-20">
    <Transition name="fade">
      <EmployeeNavigation v-if="!isManagerView"/>
    </Transition>
    <div class="w-1/5">
      <LangRouterLink :to="{name: isManagerView ? $consts.ROUTES.MANAGER.HUB : $consts.ROUTES.EMPLOYEE.STAND_UP}" class="w-32 transition-transform block hover:scale-[0.98]">
        <Logo/>
      </LangRouterLink>
    </div>
    <div class="w-4/5 flex justify-between items-center">
      <div>
        <LangRouterLink v-if="$route.name === $consts.ROUTES.MANAGER.EMPLOYEE"
                        :to="{name: $consts.ROUTES.MANAGER.EMPLOYEES}"
                        class="flex items-center hover:-translate-x-1 transition-transform">
          <Icon id="arrow" class="rotate-180 text-light_gray-900 text-tn"/>
          <span class="ml-2">{{ $t('back_to_employees') }}</span>
        </LangRouterLink>
      </div>
      <div class="flex justify-end items-center w-3/5 gap-6">
        <div class="grow flex justify-end items-center">
          <Transition name="fade" mode="out-in">
            <form v-if="isManagerView" action="" class="w-full" @submit.prevent>
              <input type="text" id="search" class="h-10 w-full bg-light_gray-700 px-5 rounded-lg disabled:cursor-not-allowed"
                     :placeholder="$t('find_something')" disabled>
              <label for="search" class="sr-only">Search</label>
            </form>
            <EmployeeProfile v-else/>
          </Transition>
        </div>
        <IconButton v-if="canSwitchView" :help="$t(`view_switch.${isManagerView ? 'employee' : 'manager'}`)"
                    icon="lime_switch" bottom
                    @click="$router.push({name: isManagerView ? $consts.ROUTES.EMPLOYEE.STAND_UP : $consts.ROUTES.MANAGER.EMPLOYEES })"/>
      </div>
    </div>
  </nav>
</template>

<script>
import {Icon, LangRouterLink} from '@/components/helpers'
import {EmployeeNavigation, EmployeeProfile} from '@/components/navigation/blocks'
import {IconButton, Logo} from '@/components/small'

export default {
  name: 'TopNavigation',
  components: {EmployeeNavigation, EmployeeProfile, Icon, IconButton, LangRouterLink, Logo},
  computed: {
    canSwitchView() {
      return this.isManagerView ? this.$auth.user.employee : this.$auth.user.manager
    },
    isManagerView() {
      return this.$route.matched.some(({name}) => name === this.$consts.ROUTES.MANAGER.HOME)
    }
  }
}
</script>