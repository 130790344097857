import LOCALE from '@/config/locale.js'
import {createI18n} from 'vue-i18n'

import enDefault from '@/i18n/en/default.js'
import enFilters from '@/i18n/en/filters.js'

import frDefault from '@/i18n/fr/default.js'
import frFilters from '@/i18n/fr/filters.js'

export default createI18n({
    locale: LOCALE.DEFAULT,
    fallbackLocale: LOCALE.DEFAULT,
    messages: {
        en: {
            ...enDefault,
            filters: enFilters
        },
        fr: {
            ...frDefault,
            filters: frFilters
        },
    }
})
