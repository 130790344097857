<template>
  <Transition name="fade">
    <p v-if="tooltip.visible"
       :class="['z-20 w-max bg-black text-white-900 px-3 py-2 rounded text-sm transition-all duration-300 pointer-events-none', {
         '-translate-x-1/2 -translate-y-1/2': !bottom && !fixed,
         'absolute': !fixed,
         'fixed': fixed,
         '-translate-x-full': fixed && bottom,
         '-translate-x-1/2': fixed && !bottom
       }]"
       :style="`${bottom && !fixed ? 'right' : 'left'}: ${tooltip.x}; top: ${tooltip.y}`">
      <span>{{ tooltip.text }}</span>
      <span
          :class="['w-0 h-0 border-r-[5px] border-r-transparent border-l-[5px] border-l-transparent border-t-[6px] border-t-black absolute', {
            'left-1/2 -translate-x-1/2 translate-y-full bottom-[1px]': !bottom,
            'right-[9px] rotate-180 -top-[5px]': bottom
          }]"></span>
    </p>
  </Transition>
</template>

<script>
export default {
  name: 'TooltipHover',
  props: {
    bottom: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: Object,
      required: true
    }
  }
}
</script>