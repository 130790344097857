<template>
  <button :class="['button', {'button-secondary': secondary}]">
    <Icon :id="icon" class="mr-2 -mt-0.5"/>
    <span>{{ label }}</span>
  </button>
</template>

<script>
import {Icon} from '@/components/helpers'

export default {
  name: 'IconTextButton',
  components: {Icon},
  props: {
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    secondary: {
      type: Boolean,
      default: false
    }
  }
}
</script>