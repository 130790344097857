<template>
  <button class="flex gap-3 items-start overflow-hidden transition-transform h-9 hover:scale-[0.98]" @click.stop="$modal.openAvatar">
    <img :src="$auth.user.avatar" alt="" :class="['rounded aspect-square shrink-0 h-9 relative z-10 transition-[margin]', {'mx-2': $preferences.navigation.collapsed}]">
    <span v-if="!collapsed" class="text-left">
      <span class="w-max block">{{ $auth.user.full_name }}</span>
      <span class="text-sm mt-0.5">{{ job }}</span>
    </span>
  </button>
</template>

<script>
export default {
  name: 'ManagerProfile',
  props: {
    collapsed: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    job() {
      return this.$route.name.includes('manager') ? this.$auth.user.manager.job : this.$auth.user.employee.job
    }
  }
}
</script>